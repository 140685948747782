.footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-a);
  background-color: var(--color-d);
  justify-content: center;
  align-content: center;
  padding-left: 2em;
  padding-right: 2em;
  gap: 30px;
  min-height: 100vh;
  text-align: center;
  letter-spacing: 1px;
}

.footer p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  gap: 30px;
}
.footer p em a{
  text-decoration: underline;
  color: var(--color-a);
  cursor: pointer;
  transition: color 0.5s ease-in-out;
}
.footer p em a:hover{
  color: var(--color-b);
}
.footer ol{
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 30px;
}
@media screen and (width<=768px) {
  .footer ol li img{
    width: 8.5vw;
  }
}
@media screen and (width>768px) {
  .footer ol li img{
    min-width: 80px;
  }
}
.footer .mail{
  font-size: 5vw;
  text-underline-offset: 12px;
}