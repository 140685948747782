#root{
  background-color: var(--color-b)
}
/* handle the layout of the desktop nav */
@media screen and (width>=768px) {
  *{
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: 'Monsterrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1d1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --color-a: #ebe1e1;
  --color-b: #1d1d1f;
  --color-c: rgb(206, 206, 206);
  --color-d: #1a1919;
  --color-e: #f8eded;
  --color-f: #ade0f8;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Monsterrat', sans-serif;
}
/* scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1d1d1f;
}
::-webkit-scrollbar-thumb {
  background: var(--color-c);
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.expand {
  z-index: 1;
  cursor: pointer;
  transition: 0.5s;
}
.expand:hover{
  transform: scale(1.2);
  cursor: pointer;
}

#root .skills{
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 2em;
  padding-right: 2em;
}
.hidden{
  display: none;
}
.app-home-wrapper{
  display: flex;
  flex-direction: row-reverse;
}
.App{
  width: 100%;
  display: flex;
  flex-direction: column;
}

#root a{
  transition: color 0.5s ease-in-out;
}
#root a:hover{
  color:#b0fdc7;
  cursor: pointer;
}