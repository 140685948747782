@media screen and (min-width: 700px) {

  .project-info-container h2{
    font-size: 2.2em;
  }

  .nav-open li a{
    font-size: 2.3em;
  }
  .project-item .project-info-container a{
    font-size: 1.7em;
  }
  .about{
    font-size: 1.7em;
  }
  
  .footer p{
    font-size: 2.3em;
  }
  
  .project-item .project-info-container p{
    font-size: 1.5em;
  }
  
  .footer .mail{
    font-size: 1em;
  }

  .skills .skills-container li{
    font-size: 2em;
  }
}

@media screen and (min-width: 1200px) {

  .nav-open li a {
    font-size: 2em;
  }

  .about,
  .skills .skills-container li{
    font-size: 2.1em;
  }
}


@media screen and (min-width:400px){
  .intro-content h1{
    font-size: 25px;
  }
  .intro-content h2{
    font-size: 22px;
  }
}
@media screen and (min-width: 767px){
  .intro-content h1{
    font-size: 21.5px;
  }
  .intro-content h2{
    font-size: 17.5px;
  }
}
@media screen and (min-width:1200px){
  .intro-content h1{
    font-size: 33px;
  }
  .intro-content h2{
    font-size: 25px;
  }
}
@media screen and (min-width:1500px){
  .intro-content h1{
    font-size: 45px;
  }
  .intro-content h2{
    font-size: 32px;
  }
}