.about{
  padding: 1.8em;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  font-size: 5vw;
  padding-top: 4em;
  padding-bottom: 4em;
}
.about-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-e);
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 900px;
}
.about-title{
}
.about-text{
  width: 100%;
  max-width: 850px;
  letter-spacing: 1px;
}